<template>
  <div class="app-container alarm-detail-comp" v-loading="loading">
    <div class="card-container">
      <el-row :gutter="48" class="detail-line">
        <el-col :span="12" class="detail-col">
          <div class="detail-label">{{ $t("auditLog.description") }}</div>
          <div class="detail-value">
            {{ detailData ? detailData.message || "-" : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">操作</div>
          <div class="detail-value">
            {{ detailData ? detailData.operation : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">操作时间</div>
          <div class="detail-value">
            {{
              detailData.operationTimestamp | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">操作账号</div>
          <div class="detail-value">
            {{ detailData ? detailData.userAccount || "-" : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">ip地址</div>
          <div class="detail-value">
            {{ detailData ? detailData.ip : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">操作主机</div>
          <div class="detail-value">
            {{ detailData ? detailData.host : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">租户名称</div>
          <div class="detail-value">
            {{ detailData ? detailData.tenantName || "-" : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">模块</div>
          <div class="detail-value">
            {{ detailData ? detailData.module : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">所属应用</div>
          <div class="detail-value">
            {{ detailData ? detailData.sourceApp : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">来源</div>
          <div class="detail-value">
            {{ detailData ? detailData.source : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">对象</div>
          <div class="detail-value">
            {{ detailData ? detailData.object : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">请求状态</div>
          <div class="detail-value">
            {{ detailData ? detailData.opResult : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">停留时长（毫秒）</div>
          <div class="detail-value">
            {{ detailData ? detailData.stayTime : "-" }}
          </div>
        </el-col>
      </el-row>
    </div>

    <template v-if="detailData.operation == 'query'">
      <div class="card-container">
        <div class="title-line">
          <span> 查询参数 </span>
        </div>
        <ace-editor
          v-model="detailData.param"
          @init="editorInit"
          lang="json"
          theme="chrome"
          style="border: 1px solid #ebecec; margin-top: 15px"
          height="220px"
          width="100%"
        >
        </ace-editor>
        <!-- <div class="alter-detail" style="margin-top: 20px">
          <span>{{ detailData ? detailData.param : "-" }}</span>
        </div> -->
      </div>
    </template>
    <template v-else>
      <div class="card-container">
        <div class="title-line">
          <span> 变更详情 </span>
        </div>
        <div class="alter-detail" style="margin-top: 20px">
          <el-table
            :data="tableDatas"
            :cell-style="tableCellStyle"
            border
            fit
            highlight-current-row
            stripe
          >
            <el-table-column
              type="index"
              align="center"
              :resizable="false"
              :label="$t('commons.index')"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="clmName"
              :label="$t('auditLog.columnName')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="clmComment"
              :label="$t('auditLog.columnDesc')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="oldValue"
              :label="$t('auditLog.oldValue')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="newValue"
              :label="$t('auditLog.newValue')"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { findAlarmDetail } from "@/api/ruge/vlink/alarm/alarm";
import { deepClone } from "@/utils/utils.js";
import { dateFormat } from "@/filters/index.js";
import { getLogDetail } from "@/api/ruge/logger/auditLog";
import AceEditor from "vue2-ace-editor";
const AlarmLevelEmuObj = {
  info: "提示",
  warn: "一般",
  error: "严重",
};
const AlarmTypeEmuObj = {
  run: "运行",
  fault: "故障",
};

export default {
  name: "alarmDetail",
  components: { AceEditor },
  data() {
    return {
      AlarmLevelEmuObj,
      AlarmTypeEmuObj,
      alarmState: "alarm",
      detailInfo: {},
      loading: false,
      tableDatas: [],
      detailData: {},
    };
  },
  watch: {},
  created() {
    console.log(1);
    const { alarmId } = this.$route.query;
    alarmId && this.getAlarmDetailById(alarmId);

    const { logUUID } = this.$route.query;
    logUUID && this.getLogDetail(logUUID);
    let detailDatas = sessionStorage.getItem("logger-detail-datas");
    var obj = JSON.parse(detailDatas);
    obj.param = obj.param ? this.jsonParseHandler(obj.param) : "{}";
    this.detailData = obj;
  },
  methods: {
    jsonParseHandler(content) {
      try {
        return JSON.stringify(JSON.parse(content), null, "\t");
      } catch (error) {
        return "";
      }
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/mode/python");
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    getLogDetail(logUUID) {
      getLogDetail({ logUUID }).then((res) => {
        this.tableDatas = res;
        console.log("213232", res);
      });
    },
    // 背景颜色处理
    tableCellStyle({ row }) {
      if (row.newValue != row.oldValue) {
        return "color:red";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.alarm-detail-comp {
  padding: 40px 24px;
  .card-container + .card-container {
    margin-top: 24px;
  }
  .card-container {
    padding: 32px;
    background-color: #f8f8f8;
    .title-line {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 10px;
    }
    .detail-line {
      font-size: 12px;
      color: #000000;
      .detail-col {
        margin-top: 20px;
        .detail-label {
          height: 18px;
          line-height: 18px;
          opacity: 0.7;
          font-weight: 300;
          color: #4a4a4a;
        }
        .detail-value {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          font-weight: normal;
          margin-top: 4px;
          color: #000000;
        }
      }
    }
    .jump-to-device {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #0486fe;
      cursor: pointer;
      .iconfont {
        margin-left: 6px;
      }
    }
  }
}
</style>